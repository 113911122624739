import { createStore } from 'vuex'

export default createStore({
  state: {
    station: {
      stationData: {
        name: '',
        city: 2,
        img: '',
        radioChecked: 0,
        fm: {
          frecuencia: '',
          cobertura: '',
          siglas: ''
        },
        am: {
          frecuencia: '',
          cobertura: '',
          siglas: ''
        },
        styleMusic: [],
        description: '',
        idStation: 0
      },
      listUsers: [],
      audience: {
        gender: {
          isMale: false,
          isFemale: false
        },
        age: [0, 0, 0, 0, 0, 0, 0, 0],
        socioeconomic: {
          ab: false,
          c: false,
          d: false
        }
      },
      dataTarifa: [],
      dataTarifa2: []
    },
    userAdvertiser: {
      data: {
        name: '',
        img: ''
      },
      listUsers: []
    },
    campaign: {
      dataCampaign: {
        advertiser: 1,
        name: 'Prueba',
        timer: 1,
        start: new Date(),
        ending: new Date(),
        location: [{ id: 1, name: 'Acapulco', estimate: 200, stations: 2 }, { id: 2, name: 'Aguascalientes', estimate: 300, stations: 3 }],
        estimate: 200,
        number: ''
      },
      rangeDays: {
        range: 1,
        schedule: 1
      },
      audience: {
        gender: 1,
        year: 1,
        socioeconomic: 1,
        genderMusical: [{ id: 1, name: 'Balada en español' }, { id: 2, name: 'Clásicos del Rock' }, { id: 3, name: 'Deportes' }, { id: 4, name: 'Grupera' }, { id: 5, name: 'Grupera Cumbia' }],
        moreGenderMusicals: true
      },
      uploadMp3: {
        name: 'Hotsale_CDMX_Ago2023',
        listMp3: [{ id: 'audio3', name: '008576979_prev.mp3', audio: '', value: 33.3 }, { id: 'audio21', name: 'himno-argentino-cort.mp3', audio: '', value: 33.3 }, { id: 'audio17', name: 'eye-tiger.mp3', audio: '', value: 33.3 }],
        totalSum: 0,
        personalizationSpots: false,
        listSpotsCity: [{ id: 1, name: 'Acapulco', audios: [{ id: 'audio3', name: '008576979_prev.mp3', audio: '', value: 33.3 }, { id: 'audio21', name: 'himno-argentino-cort.mp3', audio: '', value: 33.3 }, { id: 'audio17', name: 'eye-tiger.mp3', audio: '', value: 33.3 }] }, { id: 2, name: 'Aguascalientes', audios: [{ id: 'audio3', name: '008576979_prev.mp3', audio: '', value: 33.3 }, { id: 'audio21', name: 'himno-argentino-cort.mp3', audio: '', value: 33.3 }, { id: 'audio17', name: 'eye-tiger.mp3', audio: '', value: 33.3 }] }]
      },
      selectedStation: {
        cpm: 0,
        listStation: {
          station0: {
            city: 'Acapulco',
            list: [
              {
                id: 1,
                impacto: '250 K',
                cpm: '$3.50',
                frecuencia: 'g',
                presupuesto: 200,
                spots: 5,
                days: 20,
                spotsTotales: 100,
                group: 'Radiorama',
                frecuency: '89.3 FM',
                emisoras: 'La Poderosa',
                station: 'XHMAC'
              }
            ]
          },
          station1: {
            city: 'Aguascalientes',
            list: [
              {
                id: 2,
                impacto: '250 K',
                cpm: '$3.50',
                frecuencia: 'g',
                presupuesto: 200,
                spots: 5,
                days: 20,
                spotsTotales: 100,
                group: 'Radiorama',
                frecuency: '89.3 FM',
                emisoras: 'La Poderosa',
                station: 'XHMAC'
              },
              {
                id: 3,
                impacto: '250 K',
                cpm: '$3.50',
                frecuencia: 'g',
                presupuesto: 200,
                spots: 5,
                days: 20,
                spotsTotales: 100,
                group: 'Radiorama',
                frecuency: '89.3 FM',
                emisoras: 'La Poderosa',
                station: 'XHMAC'
              }
            ]
          }
        }
      }
    },
    billing: {
      id: 0,
      idList: 0
    }
  },
  getters: {
  },
  mutations: {
    addEmisora (state, value) {
      state.station.stationData = value
    },
    addUsers (state, value) {
      state.station.listUsers = value
    },
    addAudience (state, value) {
      state.station.audience = value
    },
    addTarifas (state, value) {
      state.station.dataTarifa = value
    },
    addAdvertiser (state, value) {
      state.userAdvertiser.data = value
    }
  },
  actions: {
  },
  modules: {
  }
})
