<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss" src="./_scss/main.scss"></style>
